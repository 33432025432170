import React, { useEffect,useState } from "react"
import Axios from 'axios'
import { useTablasGenerales } from "./useTablasGenerales"
import {useHelper} from '../hooks/useHelper'
import { Password } from "@mui/icons-material";
import Swal from 'sweetalert2';

const regex_solo_numeros = /^[0-9\b]+$/;
const regex_visa = /^4[0-9]{12}(?:[0-9]{3})?$/;

export const useFormulario = ()=>{
    const {formatearMontoMoneda,verificarSiFechaEsPosteriorNanios,verificarSiFechaEsAnteriorNanios,validarEmail} = useHelper()

    const {tabla,eventos,eventosValor,provincias} = useTablasGenerales()
    const [datos,setDatos] = useState({nombre:'',
                                        apellido:'',
                                        tipoPago:'mercadopago',
                                        referencia:'',
                                        id_evento:Number(process.env.REACT_APP_ID_EVENTO),
                                        pago:null,
                                        esMatrimonial:null,
                                        contingente:0,
                                        ministroUad:null,
                                        id_obrero:null,
                                        documento:'',
                                        id_evento_valor:null,
                                        descripcion:null,
                                        monto_pagado:null,
                                        sexo:null,
                                        edad:'',
                                        id_provincia:null,
                                        email:null,
                                        conyuge:{nombre:'',
                                                apellido:'',
                                                documento:'',
                                                email:null,
                                                id_provincia:null,
                                                sexo:null,
                                                edad:'',
                                                id_obrero:null,
                                                ministroUad:null}})
    const [cerrar,setCerrar] = useState(false)
    const [procesando,setProcesando] = useState(false)
    const [error,setError] = useState(null)
    const [codigoFinal,setCodigoFinal] = useState(null)
    const [infoMinistro,setInfoMinistro] = useState(null)
    const [descProvinciaTmp,setDescProvinciaTmp] = useState('')
    const [tipoParticipante,setTipoParticipante] = useState('general')
    const [nro_cupon,setNumeroCupon] = useState('')
    const [contingente,setContingente] = useState([])
    const [cuponUtilizado,setCuponUtilizado] = useState(false)

    useEffect(()=>{

        setError([])
        
        if(nro_cupon!='' && datos.referencia!=''){
            buscarEstadoCupon() // si el cupon ya se utilizó en una inscripción solo vamos a mostrar la información del mismo y la solicitud de la constancia
        }

    },[datos.referencia,nro_cupon])

    const handleChangeTipoPago = (event) => {
        setDatos({...datos,tipoPago:event.target.value,referencia:'',pago:null});
    };

    const handleChangeReferencia = (event) => {

        if(tipoParticipante=='general'){
            if(regex_solo_numeros.test(event.target.value.trim()) || event.target.value.trim()==''){
                setDatos({...datos,referencia:event.target.value.trim(),pago:null});
            }
        }else{
            setDatos({...datos,referencia:event.target.value.trim(),pago:null});
        }
    };

    const handleChangeMinistroUad = (event) => {
        setDatos({...datos,ministroUad:event.target.value});
    };

    const handleChangeConyugeMinistroUad = (event) => {
        if(event.target.checked){
            setDatos({...datos,conyuge:{...datos.conyuge,ministroUad:true}});
        }else{
            reinicializarConyuge()
        }
    };

    const handleChangeDocumento = (event) => {

            if(regex_solo_numeros.test(event.target.value.trim()) || event.target.value.trim()==''){
                setDatos({...datos,documento:event.target.value});
            }

    };

    const handleChangeEdad = (event) => {

        if(regex_solo_numeros.test(event.target.value.trim()) || event.target.value.trim()==''){
                setDatos({...datos,edad:event.target.value.trim()});
        }

};

    const handleChangeDocumentoConyuge = (event) => {

        if(regex_solo_numeros.test(event.target.value.trim()) || event.target.value.trim()==''){
            setDatos({...datos,conyuge:{...datos.conyuge,documento:event.target.value}});
        }

    };

    const handleChangeEdadConyuge = (event) => {

        if(regex_solo_numeros.test(event.target.value.trim()) || event.target.value.trim()==''){
                setDatos({...datos,conyuge:{...datos.conyuge,edad:event.target.value.trim()}});
        }

    };

    const handleChangeNombreConyuge = (event) => {

        setDatos({...datos,conyuge:{...datos.conyuge,nombre:event.target.value.toUpperCase()}});

    };

    const handleChangeNombre = (event) => {

        setDatos({...datos,nombre:event.target.value.toUpperCase()});

    };

    const handleChangeApellido = (event) => {

        setDatos({...datos,apellido:event.target.value.toUpperCase()});

    };

    const handleChangeApellidoConyuge = (event) => {

        setDatos({...datos,conyuge:{...datos.conyuge,apellido:event.target.value.toUpperCase()}});

    };

    const datosMinistroOk = ()=>{

        if (datos.contingente>1){ // si el cupón es para 1 persona lo trato como si fuera una inscripción normal

            if(!datos.pago[0].email || datos.pago[0].email.trim()==''){
                return [false,`Falta completar el e-mail`]
            }

            if(errorMail(datos.pago[0].email)){
                return [false,errorMail(datos.email)]
            }

            if(!datos.id_provincia || datos.id_provincia==-1){
                return [false,`Falta completar la ubicación del contingente`]
            }

            if(!datos.pago[0].numero_doc || (Number(datos.pago[0].numero_doc) > 99999999 || Number(datos.pago[0].numero_doc) < 1000000)){
                return [false,`El número documento del solicitante no es válido`]
            }

            return [true,null]

        }

        if(datos.ministroUad=='ministrouad'){

            if(!datos.id_obrero){
                return [false,`Falta completar el id del ministro UAD`]
            }

            if(infoMinistro.nombre.trim()==''){
                return [false,`Falta completar el nombre`]
            }

            if(!infoMinistro.nro_documento || infoMinistro.nro_documento.toString().trim()==''){
                return [false,`Falta completar el número de documento`]
            }

            if(infoMinistro.nro_documento.toString().trim().length>8){
                return [false,`El número de documento es demasiado largo`]
            }

            if(infoMinistro.nro_documento.toString().trim().length<7){
                return [false,`El número de documento requiere al menos 7 dígitos`]
            }

            if(!infoMinistro.edad || Number(infoMinistro.edad)==0){
                return [false,`Falta completar la edad`]
            }

            const edad1 = Number(infoMinistro.edad) 

            if(edad1>3 && edad1<100){
            }else{
                return [false,`Edad debe ser entre 4 y 99 años`]
            }


            if(!infoMinistro.sexo || infoMinistro.sexo.trim()==''){
                return [false,`Falta completar el género`]
            }

            if(infoMinistro.email.trim()==''){
                return [false,`Falta completar el e-mail`]
            }

            if(!infoMinistro.id_provincia || infoMinistro.provincia.trim()==''){
                return [false,`Falta completar la provincia`]
            }

        }else{

            if(datos.nombre.trim()==''){
                return [false,`Falta completar el nombre`]
            }
    
            if(datos.apellido.trim()==''){
                return [false,`Falta completar el apellido`]
            }
    
            if(!datos.sexo || datos.sexo.trim()==''){
                return [false,`Falta completar el género`]
            }

            if(datos.documento.trim()==''){
                return [false,`Falta completar el número de documento`]
            }

            if(datos.documento.trim().length>9){
                return [false,`El número de documento es demasiado largo`]
            }

            if(datos.documento.trim().length<7){
                return [false,`El número de documento requiere al menos 7 dígitos`]
            }
            
            if(!datos.edad || Number(datos.edad)==0){
                return [false,`Falta completar la edad`]
            }

            const edad2 = Number(datos.edad) 

            if(edad2>3 && edad2<100){
            }else{
                return [false,`Edad debe ser entre 4 y 99 años`]
            }

            if(!datos.email || datos.email.trim()==''){
                return [false,`Falta completar el e-mail`]
            }

            if(errorMail(datos.email)){
                return [false,errorMail(datos.email)]
            }

            if(!datos.id_provincia || datos.id_provincia==-1){
                return [false,`Falta completar la ubicación`]
            }

        }
        

        return [true,null]
    }

    const handleChangeProvincia = (event) => {
        setDatos({...datos,id_provincia:Number(event.target.value)});
    };

    const handleChangeProvinciaConyuge = (event) => {
        setDatos({...datos,conyuge: {...datos.conyuge, id_provincia:Number(event.target.value)}});
    };

    const handleChangeSexoConyuge = (event) => {
        setDatos({...datos,conyuge: {...datos.conyuge, sexo:event.target.value}});
    };

    const handleChangeSexo = (event) => {
        setDatos({...datos,sexo:event.target.value});
    };

    const handleChangeMinistroUADRadio = (event) => {
        setDatos({...datos,conyuge:{...datos.conyuge,ministroUad:event.target.value=='SI' ? true: false}});
    };

    const descripcionProvincia = (id)=>{
        if(id){
            if(id>0){ // hay id provincia negativos que vienen de una vista, si son negativos no encontramos el nombre en el array de provincias sino en la vista
                return provincias.filter(item=>item.id_provincia==id)[0].nombre
            }else{
                if(descProvinciaTmp){
                    return descProvinciaTmp
                }
            }
        }else{
            return ''
        }
    }

    const datosConyugeOk = ()=>{

        if (datos.contigente>0){
            return [true,null]
        }

        if(datos.esMatrimonial)
        {

            if(datos.conyuge.nombre.trim()==''){
                return [false,`Falta completar el nombre del conyuge`]
            }

            if(datos.conyuge.ministroUad){
                if(!datos.conyuge.id_obrero){
                    return [false,`Falta el ID de ministro UAD del conyuge`]
                }
            }else{
                if(datos.conyuge.id_obrero){
                    return [false,`El conyuge no es ministro UAD pero figura un ID`]
                }

                if(datos.conyuge.apellido.trim()==''){
                    return [false,`Falta completar el apellido del conyuge`]
                }

            }
    
            if(datos.conyuge.documento.trim()==''){
                return [false,`Falta completar el número de documento del conyuge`]
            }

            if(datos.conyuge.documento.trim().length>9){
                return [false,`El número de documento del conyuge es demasiado largo`]
            }

            if(datos.conyuge.documento.trim().length<7){
                return [false,`El número de documento del conyute requiere al menos 7 dígitos`]
            }
            
            if(!datos.conyuge.edad || Number(datos.conyuge.edad)==0){
                return [false,`Falta completar la edad`]
            }

            const edad = Number(datos.conyuge.edad) 

            if(edad>3 && edad<100){
            }else{
                return [false,`Edad debe ser entre 4 y 99 años`]
            }


            if(!datos.conyuge.sexo || datos.conyuge.sexo.trim()==''){
                return [false,`Falta completar el género del conyuge`]
            }

            if(!datos.conyuge.id_provincia || datos.conyuge.id_provincia==-1){
                return [false,`Falta completar la ubicación del conyuge`]
            }


            if(!datos.conyuge.email || datos.conyuge.email.trim()==''){
                return [false,`Falta completar el e-mail del conyuge`]
            }

            if(errorMail(datos.conyuge.email)){
                return [false,errorMail(datos.conyuge.email)]
            }

            if(datos.ministroUad=='ministrouad'){
                if(datos.conyuge.documento.trim()==infoMinistro.nro_documento.toString().trim()){
                    return [false,`Los números de documento deben ser distintos`]
                }
            }else{
                if(datos.conyuge.documento.trim()==datos.documento.trim()){
                    return [false,`Los números de documento deben ser distintos`]
                }                
            }
           
        }
            return [true,null]
        

    }

    const datosPagoOK = ()=>{

        if (tipoParticipante=='general'){
            if(!datos.pago || datos?.pago?.length==0){
                return [false,`No hay pagos registrados para el número: ${datos.referencia})`]
            }
    
            if(datos.pago?.length>1){
    
                return [false,`Se encontraron múltiples movimientos para el mismo número de referencia y no podemos identificar cuál es válido (Número de operación: ${datos.referencia})`]
            }
    
            if(datos.pago.length==1 && datos.pago[0].status!='approved'){
                return [false,`Encontramos 1 pago pero no está aprobado (${datos.pago[0].status}) para el número de operación: ${datos.referencia}`]
            }
    
            if(datos.esMatrimonial==null){
                return [false,'No se pudo determinar el tipo de producto pagado (Matrimonio o Inddividual)']
            }
    
            if(datos.pago.length==1 && datos.pago[0].status=='approved'){
                // si está aprobado también me fijo si se pagó un evento por matrimonio o individual
                // si no puedo determinar eso porque no hay descripción o es vacío dejo en null y no podremos inscribir
    
                
                return [true,`El pago está aprobado para el número de operación: ${datos.referencia}`]
    
            }
        }
       
        if (tipoParticipante=='contingente'){
            if(!datos.pago || datos?.pago?.length==0){
                return [false,`No hay pagos registrados para el cupón: ${datos.referencia})`]
            }
    
            if(datos.pago?.length>1){
    
                return [false,`Se encontraron múltiples movimientos para el mismo código de cuupón y no podemos identificar cuál es válido (Código de cupón: ${datos.referencia})`]
            }
    
            if(datos.pago[0].cantidad_personas==null || !(datos.pago[0].cantidad_personas>0)){
                return [false,'No se pudo determinar la cantidad de personas asociadas al cupón']
            }
                
                return [true,`El pago está aprobado para el código de cupón: ${datos.referencia}`]
    
        }

        return [false,'El pago o el status del mismo son desconocidos']
   }

   
   const buscarPagosCupon = async ()=>{
        try{
            const {data} = await Axios.get(process.env.NODE_ENV =='development' ? `http://localhost:3020/api/eventos/cupones/pagos/${datos.referencia}/${datos.id_evento}`:`https://uadapi.uad.digital/api/eventos/cupones/pagos/${datos.referencia}/${datos.id_evento}`)
            setDatos({...datos,pago:data,
                esMatrimonial:false,
                id_evento_valor:data[0].id_evento_valor,
                descripcion:data[0].detalle,
                contingente:data[0].cantidad_personas,
                monto_pagado:formatearMontoMoneda(data[0].monto_pagado)});

        }catch(err){
            console.log(err)

            setDatos({...datos,pago:null,esMatrimonial:null,contingente:0});
            setError(['validacion_pago',err.response.data.message])
        }
   }


   const buscarEstadoCupon = async ()=>{
    try{
        const {data} = await Axios.get(process.env.NODE_ENV =='development' ? `http://localhost:3020/api/eventos/cupones/estado/${datos.referencia}/${datos.id_evento}`:`https://uadapi.uad.digital/api/eventos/cupones/estado/${datos.referencia}/${datos.id_evento}`)
        if(data.inscriptos>0){
            setCuponUtilizado(true)
        }else{
            setCuponUtilizado(false)
        }
    }catch(err){
        console.log(err)

        setDatos({...datos,pago:null,esMatrimonial:null,contingente:0});
        setCuponUtilizado(false)
        setError(['validacion_pago',err.response.data.message])
    }
}

    const buscarPagos2 = async ()=>{
        setError([])
        try{
            const evento = eventos.filter(item=>item.id_evento==datos.id_evento)[0]
            
            const {data:datosdemercadopago} = await Axios.get(process.env.NODE_ENV =='development' ? `http://localhost:3020/api/eventos/mercadopago/pagos/${evento.cod_ref_mp}/${datos.referencia}/${datos.id_evento}`:`https://uadapi.uad.digital/api/eventos/mercadopago/pagos/${evento.cod_ref_mp}/${datos.referencia}/${datos.id_evento}`)
            
            if (datosdemercadopago.length>0){

                const descripcion = datosdemercadopago[0].descripcion.trim()
                const mp_external_reference = datosdemercadopago[0].external_reference.trim()
                const tieneCodigoOpcion = descripcion.includes('#')
                const tieneItemValorEnExternalReference = mp_external_reference.includes('#')

                if(!tieneCodigoOpcion && !tieneItemValorEnExternalReference){
                    setDatos({...datos,pago:false,contingente:0,esMatrimonial:null,id_evento_valor:null,descripcion:null,monto_pagado:null});
                    setError(['validacion_pago','No se puede procesar el pago porque la descripción del producto no corresponde con un evento válido (no contiene el código de opción que lo identifica)'])
                }else{

                    // para el caso de botones de pago

                    // La descripción del item a pagar va a tener identificado el nro_opcion
                    // con un #xx
                    // por ejemplo CONVENCION UAD MATRIMONIO (2 PERSONAS) #02
                    // debeo encontrar el número 02, para ello detecto en donde está el #
                    // luego tomo las posiciones desde y hasta del substring para extraerlo
                    // lo convierto en número y luego matcheo con la tabla eventosValor para saber que
                    // características tiene el item pagado
                    
                    // para el caso de pagos por preferencias programaticas desde la api 
                    
                    // el id_item_valor lo obtenemos del external_reference, vendrá algo como #36#algún_codigo
                    // el id_item_valor lo tomamos del primer numeral, en este caso 36

                    /*
                    const posicionNumeral =  descripcion.indexOf('#',0)
                    const codigoOpcion = descripcion.substring(posicionNumeral+1,posicionNumeral+3)
                    const itemPagado = eventosValor.filter(item=>item.nro_opcion==Number(codigoOpcion))
                    */

                    const itemPagado = obtenerItemPagado(tieneCodigoOpcion,tieneItemValorEnExternalReference,descripcion,mp_external_reference,eventosValor)
                   
                    if(!itemPagado || itemPagado.length==0){
                        setDatos({...datos,pago:null,esMatrimonial:null,contingente:0,id_evento_valor:null,descripcion:null,monto_pagado:null});
                        setError(['validacion_pago',`No se encontró un # item de evento uad que coincida con el # item del pago`])
                    }else{
                        const esMatrimonial = itemPagado[0].matrimonio
                        const id_evento_valor = itemPagado[0].id_evento_valor
                        const monto_pagado = datosdemercadopago[0].monto
                        const esContingente = itemPagado[0].contingente

                        const denominador = Number(itemPagado[0].valor_mp) > 0 ? Number(itemPagado[0].valor_mp) : 1000000000

                        //const contingente = Number(itemPagado[0].valor_mp) > 0 ? Number(monto_pagado) / Number(itemPagado[0].valor_mp) : 0 
                        const contingente = 0 // Estaba preparado para calcular la cantidad de personas cuando
                                              // se creaba una preferencia por programa pero al probar el flujo de cupones genera un conflicto cuando es contigente y mercadopago al mismo tiempo
                                              // ahora lo seteo en CERO pero si en algún momento implementamos preferencias otra vez hay que redefinir esto

                        setDatos({...datos,pago:datosdemercadopago,
                                            esMatrimonial:esMatrimonial,
                                            id_evento_valor:id_evento_valor,
                                            descripcion:descripcion,
                                            contingente:contingente,
                                            monto_pagado:formatearMontoMoneda(monto_pagado)});
                    }
                }
            }
        }
        catch(err){
            console.log(err)

            setDatos({...datos,pago:null,esMatrimonial:null,contingente:0});

            if(err.response.status==400){
                setError(['validacion_pago',`El número de operación informado ya está asociado a una inscripción ${err.response.data.inscriptos.map(item=>item.nombre)}`])
            }else{
                if(err.response.status==404){
                    setError(['validacion_pago',err.response.data.message])
                }else{
                    setError(['validacion_pago','Se produjo un error al buscar el pago'])
                }
            }
        }
    }

    const buscarStatusMinistro = async (documento)=>{

        try{
            const {data} = await Axios.get(process.env.NODE_ENV =='development' ? `http://localhost:3020/api/eventos/participante/estado/${documento}/${datos.id_evento}`:`https://uadapi.uad.digital/api/eventos/participante/estado/${documento}/${datos.id_evento}`)
            return data
        }
        catch(err){
            console.log(err)
            throw new Error(err.response.data.message) // aquí se captura los errores de axios y viene el mensaje de error en err.response.data
                                // debo forzar un throw error para que en el punto en donde se llamó a la función capture el error en el catch si no no lo interpreta como un error
        }
    }

    const buscarStatusCupon = async (nro_cupon)=>{

        try{
            const {data} = await Axios.get(process.env.NODE_ENV =='development' ? `http://localhost:3020/api/eventos/cupon/estado/${nro_cupon}/${datos.id_evento}`:`https://uadapi.uad.digital/api/eventos/cupon/estado/${nro_cupon}/${datos.id_evento}`)
            return data
        }
        catch(err){
            console.log(err)
            throw new Error(err.response.data.message) // aquí se captura los errores de axios y viene el mensaje de error en err.response.data
                                // debo forzar un throw error para que en el punto en donde se llamó a la función capture el error en el catch si no no lo interpreta como un error
        }
    }

    const solicitarLinkConstancia = async (referencia,tipo)=>{

        let url = ''

        if (tipo=='nro_documento'){
            url = process.env.NODE_ENV =='development' ? `http://localhost:3020/api/eventos/participante/solicitud/link/${referencia}/${datos.id_evento}`:`https://uadapi.uad.digital/api/eventos/participante/solicitud/link/${referencia}/${datos.id_evento}`
        }else if (tipo=='cupon'){
            url = process.env.NODE_ENV =='development' ? `http://localhost:3020/api/eventos/cupon/solicitud/link/${referencia}/${datos.id_evento}`:`https://uadapi.uad.digital/api/eventos/cupon/solicitud/link/${referencia}/${datos.id_evento}`
        }else{
            alert('Falta un tipo de solicitud válida')
            return
        }

        try{
            const {data} = await Axios.get(url)
            return data
        }
        catch(err){
            console.log(err)
            throw new Error(err.response.data.message) // aquí se captura los errores de axios y viene el mensaje de error en err.response.data
                                // debo forzar un throw error para que en el punto en donde se llamó a la función capture el error en el catch si no no lo interpreta como un error
        }
    }

    const buscarDatosMinistro = async ()=>{

        try{
            const {data} = await Axios.get(process.env.NODE_ENV =='development' ? `http://localhost:3020/api/eventos/participante/info/${datos.documento}/${datos.id_evento}`:`https://uadapi.uad.digital/api/eventos/participante/info/${datos.documento}/${datos.id_evento}`)
            if (data){
                setInfoMinistro(data)
                setDatos({...datos,id_obrero:data.referencia})
                setDescProvinciaTmp(data?.provincia ? data?.provincia : '' )
                return data
            }else{
                setInfoMinistro(null);
                return null
            }
        }
        catch(err){
            console.log(err)
            throw new Error(err.response.data.message) // aquí se captura los errores de axios y viene el mensaje de error en err.response.data
                                // debo forzar un throw error para que en el punto en donde se llamó a la función capture el error en el catch si no no lo interpreta como un error
        }
    }

    const buscarDatosConyuge = async ()=>{

        try{
            const {data} = await Axios.get(process.env.NODE_ENV =='development' ? `http://localhost:3020/api/eventos/participante/info/${datos.conyuge.documento}/${datos.id_evento}`:`https://uadapi.uad.digital/api/eventos/participante/info/${datos.conyuge.documento}/${datos.id_evento}`)
            if (data){
                setDatos({...datos,conyuge:{...datos.conyuge,
                    id_obrero:data.referencia,
                    nombre:data.nombre.toUpperCase(),
                    nro_documento:data.nro_documento,
                    sexo:data.sexo,
                    id_provincia:data.id_provincia,
                    edad:data.edad,
                    esConyuge:true,
                    email:data.email
                }
            })
                setDescProvinciaTmp(data?.provincia ? data?.provincia : '' )
                return data
            }else{
                return null
            }
        }
        catch(err){
            console.log(err)
            throw new Error(err.response.data.message) // aquí se captura los errores de axios y viene el mensaje de error en err.response.data
                                // debo forzar un throw error para que en el punto en donde se llamó a la función capture el error en el catch si no no lo interpreta como un error
        }
    }

    const verificarDNIConyuge = async ()=>{

        try{
            const {data} = await Axios.get(process.env.NODE_ENV =='development' ? `http://localhost:3020/api/eventos/participante/info/${datos.conyuge.documento}/${datos.id_evento}`:`https://uadapi.uad.digital/api/eventos/participante/info/${datos.conyuge.documento}/${datos.id_evento}`)
            if (data){
                return data
            }else{
                return null
            }
        }
        catch(err){
            console.log(err)
            return null
        }
    }

    const inscribir = async ()=>{
        // const objeto = {...datos}

        if(!datosMinistroOk()[0]){
            Swal.fire({
                html:`<p>${datosMinistroOk()[1]}</p>`,
                icon: 'info',
                confirmButtonColor: '#3085d6',
            })  
            return
         }

         if(!datosConyugeOk()[0]){
            Swal.fire({
                html:`<p>${datosConyugeOk()[1]}</p>`,
                icon: 'info',
                confirmButtonColor: '#3085d6',
            })  
            return
         }

         const vector_inscriptos = cargarInscriptos(datos,infoMinistro,contingente)

         const objeto = {cabecera:{
                            id_evento:datos.id_evento,
                            cod_interno:tipoParticipante=='contingente' ? 'cupon' : datos.pago[0].status,
                            mp_id:tipoParticipante=='contingente' ? null : datos.referencia,
                            nro_cupon:tipoParticipante=='contingente' ? datos.referencia : null ,
                            id_evento_valor:datos.id_evento_valor},
                        inscriptos:vector_inscriptos
                        }
 
         try{

            console.log('tipo de participante', tipoParticipante)
              let url=''
              
              if (tipoParticipante=='general'){
                    url = process.env.NODE_ENV =='development' ? `http://localhost:3020/api/eventos/inscripcion/${datos.id_evento}` : `https://uadapi.uad.digital/api/eventos/inscripcion/${datos.id_evento}`
              }  

              if (tipoParticipante=='contingente'){
                url = process.env.NODE_ENV =='development' ? `http://localhost:3020/api/eventos/cupon/inscripcion/${datos.id_evento}` : `https://uadapi.uad.digital/api/eventos/cupon/inscripcion/${datos.id_evento}`
             }  

              const {data} = await Axios.post(url,objeto,{
                
                headers: {
                   'content-type': 'application/json'
                 }})

                 debugger

                 setCodigoFinal(data.codigo_web)
                 
                 
                 Swal.fire({
                    html:`<p>La inscripción se efectuó con el código ${tipoParticipante=='general' ? data.codigo_web : data.id_alta_web}</p>
                        <p>Se envió un mail con los datos de su inscripción a ${reconocerCorreo(datos,infoMinistro,data)}.</p>`,
                    icon: 'info',
                    confirmButtonColor: '#3085d6',
                })  

                if(tipoParticipante=='contingente'){
                    buscarEstadoCupon()
                }

         }catch(err){
             // aquí capturo el error que viene de axios y lo trae err.response.data 
             // en .data puedo encontrar lo que envío desde el request como json
             // para que la función que llama a esta lo interprete como un error debo forzarlo con throw Error
             // y lo va a recibir en err.message
             
             console.log(err?.response?.data)
             console.log(err)

             //throw new Error(err.response.data) // usaría thrown new Error si quisera que se catchee el error en la función que llamó a la función inscribir
             //en este caso lo manejo todo en el hook
             Swal.fire({
                html:`<p>${err?.response?.data?.mensaje}</p>`,
                icon: 'warning',
                confirmButtonColor: '#3085d6',
            })  

            if(tipoParticipante=='contingente'){
                buscarEstadoCupon()
            }

         }
     }

    const reinicializar = ()=>{
        setContingente([])
        setCuponUtilizado(false)
        setDatos({...datos,
                    pago:null,
                    nombre:'',
                    apellido:'',
                    sexo:null,
                    id_provincia:null,
                    email:null,
                    referencia: nro_cupon!='' ? nro_cupon : '',
                    ministroUad:null,
                    esMatrimonial:null,
                    contingente:0,
                    documento:'',
                    edad:'',
                    id_obrero:null,
                    id_evento_valor:null,
                    descripcion:null,
                    monto_pagado:null,
                    conyuge:{nombre:'',
                            apellido:'',
                            documento:'',
                            id_provincia:null,
                            email:null,
                            edad:'',
                            sexo:null,
                            id_obrero:null,
                            ministroUad:false}});
    }

    const reinicializarConyuge = ()=>{
        setDatos({...datos,
           /* nombre:'',
            apellido:'',
            sexo:null,
            id_provincia:null,
            email:null,
            edad:'',
            id_obrero:null,
            documento:'',*/
            conyuge:{
                nombre:'',
                apellido:'',
                documento:'',
                id_provincia:null,
                email:null,
                edad:'',
                sexo:null,
                id_obrero:null,
                ministroUad:false}
            })
    }

    
    const handleChangeEmail = (event) => {
        setDatos({...datos,email:event.target.value});
    };

    
    const handleChangeEmailConyuge = (event) => {

        setDatos({...datos,conyuge: {...datos.conyuge, email:event.target.value}});

    };

    const errorMail = (email)=>{

        if(!email || validarEmail(email) || email.trim()==''){
            return null
        }else{
            return 'El e-mail ingresado parece incorrecto'
        }
    }

    const buscarEventosValorPorEvento = async ()=>{

        try{
            const {data} = await Axios.get(process.env.NODE_ENV =='development' ? `http://localhost:3020/api/tablasgenerales/eventosvalor/${datos.id_evento}`:`https://uadapi.uad.digital/api/tablasgenerales/eventosvalor/${datos.id_evento}`)
            if (data){
                return data
            }else{
                return null
            }
        }
        catch(err){
            console.log(err)
            throw new Error(err.response.data.message) // aquí se captura los errores de axios y viene el mensaje de error en err.response.data
                                // debo forzar un throw error para que en el punto en donde se llamó a la función capture el error en el catch si no no lo interpreta como un error
        }
    }

    const buscarDatosCuponPorIdAltaWeb = async (id_alta_web)=>{

        try{
            const {data} = await Axios.get(process.env.NODE_ENV =='development' ? `http://localhost:3020/api/tablasgenerales/eventosvalor/${datos.id_evento}`:`https://uadapi.uad.digital/api/tablasgenerales/eventosvalor/${datos.id_evento}`)
            if (data){
                return data
            }else{
                return null
            }
        }
        catch(err){
            console.log(err)
            throw new Error(err.response.data.message) // aquí se captura los errores de axios y viene el mensaje de error en err.response.data
                                // debo forzar un throw error para que en el punto en donde se llamó a la función capture el error en el catch si no no lo interpreta como un error
        }
    }

    const crearPreferencia = async (id_evento_valor,cantidad)=>{

        try{
            const {data} = await Axios.get(process.env.NODE_ENV =='development' ? `http://localhost:3020/api/eventos/preferencia/${id_evento_valor}/${cantidad}`:`https://uadapi.uad.digital/api/eventos/preferencia/${id_evento_valor}/${cantidad}`)
            if (data){
                return data.init_point
            }else{
                return null
            }
        }
        catch(err){
            console.log(err)
            throw new Error(err.response.data.message) // aquí se captura los errores de axios y viene el mensaje de error en err.response.data
                                // debo forzar un throw error para que en el punto en donde se llamó a la función capture el error en el catch si no no lo interpreta como un error
        }
    }

    const cargarDatosCupon = (nro_cupon)=>{
        setNumeroCupon(nro_cupon)
        setDatos({...datos,referencia:nro_cupon})
    }

    const updateContingente = async (participante,borrar)=>{
/*
        setCart(prevCart => {
            const newCart = { ...prevCart };
            // do stuff with newCart
            return newCart;
          });
          */

        if(!borrar){
            const dniDuplicado1 = contingente.some(item=>item.nro_documento==participante.nro_documento)
            const dniDuplicado2 = datos?.documento==participante.nro_documento || infoMinistro?.nro_documento ==participante.nro_documento
    
            if(dniDuplicado1 || dniDuplicado2){
                alert('El DNI ingresado ya figura en la lista')
                return false
            }else{
                setContingente([...contingente,participante])
                return true
            }
        }else{
            const nuevo_array = contingente.filter(item=>item.nro_documento!=participante.nro_documento)

            setContingente(nuevo_array)
            return true
        }
      
    }

    return {datos,
            procesando,
            cerrar,
            error,
            handleChangeTipoPago,
            handleChangeReferencia,
            buscarPagos2,reinicializar,
            handleChangeMinistroUad,
            datosPagoOK,
            handleChangeDocumento,
            infoMinistro,
            buscarDatosMinistro,
            inscribir,
            datosMinistroOk,
            datosConyugeOk,
            handleChangeApellidoConyuge,
            handleChangeNombreConyuge,
            handleChangeDocumentoConyuge,
            handleChangeNombre,
            handleChangeApellido,
            handleChangeProvincia,
            handleChangeProvinciaConyuge,
            handleChangeSexo,
            handleChangeSexoConyuge,
            descripcionProvincia,
            handleChangeEmail,
            handleChangeEmailConyuge,
            errorMail,
            reinicializarConyuge,
            handleChangeEdad,handleChangeEdadConyuge,
            codigoFinal,buscarStatusMinistro,
            handleChangeConyugeMinistroUad,
            solicitarLinkConstancia,
            buscarDatosConyuge,
            buscarEventosValorPorEvento,
            handleChangeMinistroUADRadio,crearPreferencia,buscarPagosCupon,
            tipoParticipante,setTipoParticipante,cuponUtilizado,buscarStatusCupon,
        nro_cupon,cargarDatosCupon,contingente,updateContingente}
}

const cargarInscriptos = (datos,infoMinistro,contingente)=>{
    const vector_inscriptos = []
    let nombre_1 
    
    if (datos.contingente>1){

        const parse_contingente = contingente.map(item=>{ 
            return {id_obrero:item.id_obrero,
                    nro_documento:item.nro_documento,
                    sexo:item.sexo,
                    id_provincia:datos.id_provincia, // la provincia del contingente es la que se seleccionó al principio de la inscripción para todo el contingente
                    edad:item.edad,
                    email:datos.pago[0].email, // el mail del contingente es el mismo que el del solicitante y viene en el pago del cupón
                    nombre:item.nombreCompleto,
                    esConyuge:false,
                    id_obrero:null
                }
            })
        
        return parse_contingente
    }

    if (datos.ministroUad=='ministrouad'){
        nombre_1 = {
            nombre:infoMinistro.nombre.toUpperCase(),
            id_obrero:datos.id_obrero,
            nro_documento:infoMinistro.nro_documento,
            sexo:infoMinistro.sexo,
            id_provincia:infoMinistro.id_provincia,
            edad:infoMinistro.edad,
            esConyuge:false,
            email:infoMinistro.email}
    }else{
        nombre_1 = {
            nombre:`${datos.apellido.toUpperCase()}, ${datos.nombre.toUpperCase()}`,
            id_obrero:null,
            nro_documento:datos.documento,
            sexo:datos.sexo,
            id_provincia:datos.id_provincia,
            edad:datos.edad,
            esConyuge:false,
            email:datos.email}
    }

    vector_inscriptos.push(nombre_1)

    if(datos.esMatrimonial){
        const conyuge = {
            nombre:datos.conyuge.ministroUad ? datos.conyuge.nombre.toUpperCase() : `${datos.conyuge.apellido.toUpperCase()}, ${datos.conyuge.nombre.toUpperCase()}`,
            id_obrero:datos.conyuge.ministroUad ? datos.conyuge.id_obrero : null,
            nro_documento:datos.conyuge.documento,
            sexo:datos.conyuge.sexo,
            id_provincia:datos.conyuge.id_provincia,
            edad:datos.conyuge.edad,
            esConyuge:true,
            email:datos.conyuge.email}

        vector_inscriptos.push(conyuge)
    }

    return vector_inscriptos

   /* if (datos.contingente>1){

        const parse_contingente = contingente.map(item=>{ 
            return {id_obrero:item.id_obrero,
                    nro_documento:item.nro_documento,
                    sexo:item.sexo,
                    id_provincia:nombre_1.id_provincia, // la provincia del contingente es la misma que el solicitante
                    edad:item.edad,
                    esConyuge:item.esConyuge,
                    email:nombre_1.email, // el mail del contingente es el mismo que el del solicitante
                    nombre:item.nombreCompleto,
                    esConyuge:false,
                    id_obrero:null
                }
            })
        
        return vector_inscriptos.concat(parse_contingente)
    }else{
        return vector_inscriptos
    }*/
    
   
}

const reconocerCorreo = (datos,infoMinistro,respuesta)=>{
    
    if(datos.contingente>1){
        return respuesta.email
    }

    if(datos.ministroUad=='ministrouad'){
        return infoMinistro.email.trim()
    }else{
        return datos.email.trim()
    }
}

const obtenerItemPagado = (buscarEnDescripcion,buscarEnExternalReference,descripcion,external_reference,eventosValor)=>{

    let posicionNumeral;
    let itemPagado;

    if(buscarEnDescripcion && buscarEnExternalReference){
        alert('Se produjo un error al analizar el pago. Inconsistencia de códigos de pago')
        return null
    }

    if(!buscarEnDescripcion && !buscarEnExternalReference){
        alert('Se produjo un error al analizar el pago. Inconsistencia de códigos de pago')
        return null
    }

    if(buscarEnDescripcion){
        posicionNumeral =  descripcion.indexOf('#',0)
        const codigoOpcion = descripcion.substring(posicionNumeral+1,posicionNumeral+3)
        itemPagado = eventosValor.filter(item=>item.nro_opcion==Number(codigoOpcion))
    }else{
        posicionNumeral =  external_reference.indexOf('#',0)
        const id_item_valor = external_reference.substring(posicionNumeral+1,posicionNumeral+3)
        itemPagado = eventosValor.filter(item=>item.id_evento_valor==Number(id_item_valor))
    }
    
   return itemPagado  
}