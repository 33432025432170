import React, {createContext} from 'react'
import {useFormulario} from '../hooks/useFormulario'
import {useTablasGenerales} from '../hooks/useTablasGenerales'

export const formularioContext = createContext()

const Provider = formularioContext.Provider

export const FormularioContextProvider = ({children})=>{ // Exporto el Provider

    const { datos,
            handleChangeCarrera,
            handleChangeCuatrimestre,
            mandarMensaje,
            handleChangePais,
            handleChangeProvincia,
            handleChangeOtroPais,
            handleChangeOtraProvincia,
            handleChangeLocalidad,
            handleChangeDomicilio,
            handleChangeCodPostal,
            handleChangeApellido,
            handleChangeNombre,
            handleChangeNacionalidad,
            handleChangeFechaNac,
            handleChangeNivel,
            handleChangeInstrumento,
            handleChangePrograma,
            handleChangeHorario,
            handleChangeModalidad,
            handleChangeTelefono,
            handleChangeCelular,
            handleChangeInstagram,
            resetearPais,
            resetearProvincia,
            obtenerCodigoPais,
            handleChangeCodInternacional,
            handleChangeCodArea,
            obtenerCodArea,
            obtenerLongitudTelefonoPermitida,
            recuperaProvinciaAnterior,
            datosPersonalesOK,nacionalidad,sexo,
            datosUbicacionOK,
            setNacionalidadArgentina,
            handleChangeOtraNacionalidad,
            resetearNacionalidad,
            handleChangeTipoDoc,
            datosContactoOK,
            datosFinalesOK,
            finalizar,cerrar,procesando,
            handleChangeCarreras,
            handleChangeTipoTarjeta,
            handleChangeTarjeta,
            handleChangeTipoPago,
            handleChangeReferencia,
            buscarPagos2,reinicializar,handleChangeMinistroUad,datosPagoOK,
            handleChangeDocumento,infoMinistro,buscarDatosMinistro,inscribir,datosMinistroOk,
            handleChangeApellidoConyuge,handleChangeNombreConyuge,handleChangeDocumentoConyuge,
            handleChangeProvinciaConyuge,
            handleChangeSexo,
            handleChangeSexoConyuge,datosConyugeOk,
            descripcionProvincia, handleChangeEmail,handleChangeEmailConyuge,errorMail,
            reinicializarConyuge,
            handleChangeEdad,handleChangeEdadConyuge,buscarEventosValorPorEvento,
            solicitarLinkConstancia,handleChangeConyugeMinistroUad,
            buscarDatosConyuge,
            error,codigoFinal,buscarStatusMinistro,
            handleChangeMinistroUADRadio,
            crearPreferencia,buscarPagosCupon,
            tipoParticipante,setTipoParticipante,
            nro_cupon,cargarDatosCupon,cuponUtilizado,buscarStatusCupon,
            contingente,updateContingente} = useFormulario()

    const { mensaje,
            provincias,
            paises,
            cargando,
            instrumentos,
            nacionalidades,
            horarios,
            cuatrimestres,
            modalidades,
            programas,
            niveles,
            sexos,
            eventos,eventosValor,
            tiposdoc,
            carreras,
            error:errorTablasGenerales} = useTablasGenerales()

    const errorIntegrado = errorTablasGenerales ? errorTablasGenerales : error ? error : null
    // En el value de Provider va todo lo que deseo compartir con los children
    return <Provider value={{datos,
                             paises,
                             provincias,
                             instrumentos,
                             nacionalidades,
                             cargando,
                             handleChangeCarrera,
                             handleChangeCuatrimestre,
                             mandarMensaje,
                             mensaje,
                             handleChangePais,
                             handleChangeProvincia,
                             handleChangeOtroPais,
                             handleChangeOtraProvincia,
                             handleChangeLocalidad,
                             handleChangeDomicilio,
                             handleChangeCodPostal,
                             handleChangeApellido,
                             handleChangeNombre,
                             handleChangeNacionalidad,
                             handleChangeFechaNac,
                             handleChangeNivel,
                             handleChangeInstrumento,
                             handleChangePrograma,
                             handleChangeHorario,
                             handleChangeModalidad,
                             handleChangeTelefono,
                             handleChangeCelular,
                             handleChangeInstagram,
                             resetearPais,
                             resetearProvincia,
                             obtenerCodigoPais,
                             handleChangeCodInternacional,
                             handleChangeCodArea,
                             obtenerCodArea,
                             obtenerLongitudTelefonoPermitida,
                             recuperaProvinciaAnterior,datosPersonalesOK,
                             nacionalidad,sexo,
                             datosUbicacionOK,
                             setNacionalidadArgentina,
                             handleChangeOtraNacionalidad,
                             handleChangeTipoDoc,
                             resetearNacionalidad,
                             datosContactoOK,
                             datosFinalesOK,finalizar,cerrar,procesando,
                             handleChangeCarreras,
                             horarios,
                             cuatrimestres,
                             modalidades,
                             programas,
                             niveles,
                             sexos,
                             tiposdoc,
                             carreras,
                             handleChangeTipoTarjeta,
                             handleChangeTarjeta,
                             datosPagoOK,
                             evento:eventos.filter(item=>item.id_evento==datos.id_evento)[0],
                             eventosValor,
                             handleChangeTipoPago,
                             handleChangeReferencia,
                             buscarPagos2,reinicializar,handleChangeMinistroUad,
                             datosPagoOK,
                             handleChangeDocumento,inscribir,
                             handleChangeApellidoConyuge,handleChangeNombreConyuge,handleChangeDocumentoConyuge,
                             error:errorIntegrado,infoMinistro,buscarDatosMinistro,datosMinistroOk,
                             handleChangeProvinciaConyuge,datosConyugeOk,
                             handleChangeSexo,
                             handleChangeSexoConyuge,
                             descripcionProvincia, handleChangeEmail,handleChangeEmailConyuge,errorMail,
                             reinicializarConyuge,
                             handleChangeEdad,handleChangeEdadConyuge,buscarEventosValorPorEvento,
                             solicitarLinkConstancia,handleChangeConyugeMinistroUad,
                             buscarDatosConyuge,
                             codigoFinal,buscarStatusMinistro,
                             handleChangeMinistroUADRadio,
                             crearPreferencia,buscarPagosCupon,
                             tipoParticipante,setTipoParticipante,
                             nro_cupon,cargarDatosCupon,cuponUtilizado,buscarStatusCupon,
                             contingente,updateContingente}}> 
        <div>
            {children}
        </div>
    </Provider>
}
