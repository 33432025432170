import React, {useContext, useEffect, useRef,useState} from 'react'
import {TextField, 
        Button, 
        Box ,
        Typography,
        Checkbox,
        FormControlLabel,
        Select,makeStyles ,
        LinearProgress ,
        Grid,
        CardActions,
        CardContent,
        CardMedia,CardHeader } from "@material-ui/core";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {Finalizar,NumeroTarjeta,TiposTarjeta,Carrera,Instrumento,Opciones,Programa,Modalidad,Horario,Cuatrimestre,Ubicacion,Telefonos,GenericCard} from './index'
import {useHelper} from '../hooks/useHelper'
import {useCards} from '../hooks/useCards'
import {Dialogos} from '../componentes/Dialogos'

import Alert from '@mui/material/Alert';
import { useTimePickerDefaultizedProps } from '@mui/x-date-pickers/TimePicker/shared';

export const LayoutCards = ()=>{
    const { datos,
            tipoParticipante,
            cargando} = useCards()
    const {fechaCambioFormato,hacerScroll} = useHelper()
    const [abrirDialogo,setAbrirDialogo] = useState(true)
    const [error,setError] = useState(null)
    const [activarLoading,setActivarLoading] =useState(false)
    const solicitudPreparada =  useRef(false)
    const [datosConfirmados,setDatosConfirmados] = useState(false)

    const useStyle = makeStyles({
        selectpais: {
            background:datos.pais > 0 ? 'white' : '#D3D3D3',
            color:datos.pais > 0 ? 'black' : 'gray'
        },
        selectprovincia: {
            background:datos.provincia > 0 ? 'white' : '#D3D3D3',
            color:datos.provincia > 0 ? 'black' : 'gray'
        },
        prueba:{
            background:'black',
            color:'white'
        }
      });

    
    const classes = useStyle();

    if (cargando) {
        return <p>Cargando...</p>
    }

    return <Box sx={{display:'flex',maxWidth:'800px',marginLeft:'auto',marginRight:'auto', flexDirection:'column', padding:'1px', justifyContent:'center',background:'#E7EBF0'}}> 
        <div>
            <img src={process.env.REACT_APP_IMAGEN_EVENTO} alt="Convención" style={{width:'100%'}} />
            
            <GenericCard titulo={process.env.REACT_APP_TITULO_EVENTO} id='tl-ubicacion' 
                        mostrar={true} 
                        error={false}
                        subtitulo={tipoParticipante=='general' ? 'Para inscribirse a éste evento por favor siga las instrucciones' : 'Inscripción de contingentes y otros'}
                        mensajeError = {'nada'}
                        >
                            <Opciones/>
             </GenericCard>
       

            <Dialogos open={datosConfirmados} 
                    titulo='Para continuar por favor ingresá los datos de tu tarjeta y aceptá los términos y condiciones' 
                    subtitulo='Subtítulo' 
                    procesarCancelar = {()=>alert(1)}
                    error={false}>
                            <h1>algo</h1>
                   </Dialogos>
        </div>
     </Box>
}

